@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

html {
    scroll-behavior: smooth;
}

body {
    background-color: #fafafa;
    color: hsl(0, 0%, 46%);
    font-family: "Poppins", sans-serif;
}


.home_img {
    background-image: url(./assets/mypic.jpeg);
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
    order: 1;
    justify-self: center;
    width: 263px;
    background-size: contain;
    height: 295px;
    animation: blurImage 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both, profile_animate 8s ease-in-out infinite 0s;
    ;

}

@keyframes profile_animate {
    0% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }

    50% {
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }

    100% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}

.wheel {
    animation: scroll 2s ease infinite;
}

@keyframes scroll {
    0% {
        transform: translateY(0);
    }

    30% {
        transform: translateY(3.75rem);
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-12-11 20:0:33
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-focus-in
 * ----------------------------------------
 */
@-webkit-keyframes blurImage {
    0% {
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
    }

    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

@keyframes blurImage {
    0% {
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
    }

    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}



.Topfall {
    -webkit-animation: Topfall 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s both;
    animation: Topfall 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s both;
}

.Topfall2 {
    -webkit-animation: Topfall 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.4s both;
    animation: Topfall 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.4s both;
}

.Topfall3 {
    -webkit-animation: Topfall 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.7s both;
    animation: Topfall 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.7s both;
}



@-webkit-keyframes Topfall {
    0% {
        -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
        transform: translateY(-600px) rotateX(-30deg) scale(0);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) rotateX(0) scale(1);
        transform: translateY(0) rotateX(0) scale(1);
        -webkit-transform-origin: 50% 1400px;
        transform-origin: 50% 1400px;
        opacity: 1;
    }
}

@keyframes Topfall {
    0% {
        -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
        transform: translateY(-600px) rotateX(-30deg) scale(0);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) rotateX(0) scale(1);
        transform: translateY(0) rotateX(0) scale(1);
        -webkit-transform-origin: 50% 1400px;
        transform-origin: 50% 1400px;
        opacity: 1;
    }
}
















.inputLetterDrop {
    -webkit-animation: inputLetterDrop 2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: inputLetterDrop 2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}





/* ----------------------------------------------
 * Generated by Animista on 2022-12-16 23:1:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-bottom
 * ----------------------------------------
 */
@-webkit-keyframes inputLetterDrop {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 0;
    }
}

@keyframes inputLetterDrop {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 0;
    }
}